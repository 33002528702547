import React, { useState } from "react";
import musicFile from "./barney.mp3";
import { Modal} from "react-bootstrap";
import { useMediaQuery } from "@mui/material";
import Navbar from "./NavBar/Navbar";
import NavbarMobile from "./NavBar/NavbarMobile";
import Desktop from "./Desktop";
import Desktopthree from "./Desktopthree";

import Mobile from "./Mobile";

import Mobilethree from "./Mobilethree";


import boot from './love.png'


const Home = () => {
  const [showModal, setShowModal] = useState(true);

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));


  const handleYesClick = () => {
    // Code to play music
    const audio = new Audio(musicFile);
    audio.loop = true;
    audio.play();
  };



  return (
    <div className="mainContent">


<div className="garden">
      {/* THIS JAVASCRIPT CODE IS FOR Header of the Park Page*/}

      {/* <Slider /> */}
      {!smallScreen && (
        <div className="desk">
          <Navbar/>
        <Desktop/>
        <Desktopthree/>
  
     </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Desktop View when you press on CustomSlider with ctrl and right arrow you move towards the Slider Page Desktop View*/}
      {smallScreen && (
         <div className="mob">
            <NavbarMobile/>
         <Mobile/>
  
         <Mobilethree/>
       </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Mobile View when you press on MobileSlider with ctrl and right arrow you move towards the Slider Page Mobile View*/}
    </div>
    <>  
    <Modal
     backdrop="static"
        show={showModal}
       
        keyboard={false}
        onHide={() => {}}
        className=""
      >
        <
        >
    
     
     
        <div className="sunken-panel w-full ">
        <>
        <br/>
        <h1 class="opacity-bg matrix-text text-4xl md:text-5xl mb-4 text-center token_text doyou" >Barney Here
<br/>
        And remember, I love you!

</h1>


<img  src={boot} style={{width:"100%"}}/>
        </>
      
        <div className='centerall'>
        <br/>
        <button className='button'   onClick={() => {
              handleYesClick();
              setShowModal(false);
            }}
            style={{width:"50%"}}
            >Yes</button>
      
        </div>
        <br/>
    </div>
        <>
        <div >        <  >
        
        </> </div>
        
        </>
   
  
        </>
  
      </Modal>
      
      </>
        {/* 
        
        
        
        
        */}
  
   
   
    </div>
  );
};

export default Home;
