import React from 'react'
import { Grid } from '@mui/material'
import logo from './blogo.png'
function Navbar() {
  return (
    <div className='container backof_nav bgs'>
      
  <Grid container spacing={2} >
  <Grid item md={12} lg={4} xs={12} sm={12} >
<img src={logo} style={{width:"200px"}}/>
</Grid>
<Grid item md={6} lg={2} xs={12} sm={12} style={{position:"relative" , top:"40px" , textDecoration:"none"}} >
<a href='' className='button '>Twitter</a>
</Grid>
<Grid item md={6} lg={2} xs={12} sm={12} style={{position:"relative" , top:"40px"  , textDecoration:"none"}}>
<a href='' className='button '>Telegram</a>
</Grid>
<Grid item md={6} lg={2} xs={12} sm={12} style={{position:"relative" , top:"40px"  , textDecoration:"none"}}>
<a href='' className='button'>Chart</a>
</Grid>
<Grid item md={6} lg={2} xs={12} sm={12} style={{position:"relative" , top:"40px"  , textDecoration:"none"}}>
<a href='' className='button'>Buy Now</a>
</Grid>
  </Grid>
      
      </div>
  )
}

export default Navbar