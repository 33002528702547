import React from 'react'
import { Grid } from '@mui/material'
import anos from './download__3_-removebg-preview.png'
import uni from './ray.png'
import dex from './ds.png'
import anoss from './doit.webp'
import { FaArrowAltCircleDown } from "react-icons/fa";
import lasto from './lasto.png'
import noot1 from './b1.jpeg'
import noot2 from './b2.jpeg'
import noot3 from './b3.jpeg'

import noot4 from './b4.jpeg'
import noot5 from './b5.jpeg'
import noot6 from './bc.jpg'


import fine from './bur.mp4'

function Desktop() {
  return (
    <div >
      <br/>   <br/>
        
        
      <div className='container'><Grid container>
          <Grid item md={12} xs={12} lg={12} className='centerall'>
                <img src={anos} className='image_anos'/>
         </Grid>
          </Grid></div>
          

          <br/>  <br/><br/><br/>
<div className='colorof_firstbg'><br/><div className='container'>
  
   <Grid container>
          <Grid item md={12} xs={12} lg={12} className='centerall'>
          <h1 class="opacity-bg matrix-text text-4xl md:text-5xl mb-4 text-center anos_text">Barney
          <br/>
          Contract Address
          
          </h1>
         </Grid>
         <Grid item md={12} xs={12} lg={12} className='centerall'><br/><br/>
          <p className='anon_p'> Launching Soon </p>
         </Grid>

       
          </Grid></div> <br/>  <br/> 
          <div className='container'>    <Grid container>
          <Grid item md={6} xs={6} lg={6} className='centerall'>

          <h1 class="opacity-bg matrix-text text-4xl md:text-5xl mb-4 text-center anos_text">
            Buy Here
          
    
          </h1>
         
       
          <br/>  <br/>

        
         </Grid>
         <Grid item md={6} xs={6} lg={6} className='centerall'>
         <h1 class="opacity-bg matrix-text text-4xl md:text-5xl mb-4 text-center anos_text">
            Chart Here
          
    
          </h1>
       
          <br/>  <br/>
      
         </Grid>
          </Grid></div>
           <div className='container'>    <Grid container>
          <Grid item md={6} xs={6} lg={6} className='centerall'>


         <FaArrowAltCircleDown style={{color:"#fff"}}/>
       
          <br/>  <br/>

        
         </Grid>
         <Grid item md={6} xs={6} lg={6} className='centerall'>
         <FaArrowAltCircleDown style={{color:"#fff"}}/>
       
          <br/>  <br/>
      
         </Grid>
          </Grid></div>
          <div className='container'>    <Grid container>
          <Grid item md={6} xs={6} lg={6} className='centerall'>


            <a href=''>   <img src={uni} className='image_anos_uni'/></a>
       
          <br/>  <br/>

        
         </Grid>
         <Grid item md={6} xs={6} lg={6} className='centerall'>
          <a href=''>   <img src={dex} className='image_anos_dex'/></a>
       
          <br/>  <br/>
      
         </Grid>
         <Grid item md={12} xs={12} lg={12} className='centerall'>

<img src={anoss} className='image_anosa'/>
</Grid>
          </Grid></div>
          
       
          <br/> <br/>
          
          
      
          
          </div>

         

        
    
          
       
          
      




  


   <div className='backof_greens'>

<div className='container'>
      


<Grid item md={12} xs={12} lg={12} className='centerall'>
          <h1 class="opacity-bg matrix-text text-4xl md:text-5xl mb-4 text-center anos_text">Barney
          <br/>
Dance
          </h1>
         </Grid>





       <Grid container spacing={2}>
      
      <br /> <br />
      <Grid container spacing={2}>
      <Grid item md={12} xs={12} lg={12} sm={12} className='centerallvideo'>
        <div className='css-hywirf'>
          <video width="100%" height='100%' controls muted >
            <source src={fine} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </Grid>
    </Grid>
        <br /> <br />
 
    
       </Grid>
<br/><br/><br/>
</div>



</div>


<div className='backof_green'>

<div className='container'>
       <Grid container spacing={2}>
       <Grid item md={12} xs={12} lg={12} className='centerall'>
       <h1 class="opacity-bg matrix-text text-4xl md:text-5xl mb-4 text-center anos_text">Barney
       <br/>
Meme
       
       </h1>
      </Grid>
      <Grid item md={4} xs={12} lg={4} className='centerall'><br/><br/>
      <img src={noot1} style={{width:"100%" , borderRadius:"10px"}}/>
      </Grid>
      <Grid item md={4} xs={12} lg={4} className='centerall'><br/><br/>
      <img src={noot2} style={{width:"100%" , borderRadius:"10px"}}/>
      </Grid>
      <Grid item md={4} xs={12} lg={4} className='centerall'><br/><br/>
      <img src={noot3} style={{width:"100%" , borderRadius:"10px"}}/>
      </Grid>
    
       </Grid>









       <Grid container spacing={2}>
       <Grid item md={12} xs={12} lg={12} className='centerall'>
     
      </Grid>
      <Grid item md={4} xs={12} lg={4} className='centerall'><br/><br/>
      <img src={noot4} style={{width:"100%" , borderRadius:"10px"}}/>
      </Grid>
      <Grid item md={4} xs={12} lg={4} className='centerall'><br/><br/>
      <img src={noot5} style={{width:"100%" , borderRadius:"10px"}}/>
      </Grid>
      <Grid item md={4} xs={12} lg={4} className='centerall'><br/><br/>
      <img src={noot6} style={{width:"100%" , borderRadius:"10px"}}/>
      </Grid>
    
       </Grid>
<br/><br/><br/><br/><br/><br/>
</div>



</div>


    </div>
  )
}

export default Desktop