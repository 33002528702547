import React from 'react'
import { Grid } from '@mui/material'
import logo from './blogo.png'
function Navbar() {
  return (
    <div className='container backof_nav bgs'>
      
  <Grid container spacing={2}>
  <Grid item md={12} lg={4} xs={12} sm={12} className='centeritall'>
<img src={logo} style={{width:"100px"}}/>
</Grid>
<Grid item md={6} lg={2} xs={6} sm={6} className='centeritall'>
<a href='' className='buttonm centeritall'>Twitter</a>
</Grid>
<Grid item md={6} lg={2} xs={6} sm={6} className='centeritall'>
<a href='' className='buttonm centeritall'>Telegram</a>
</Grid>
<Grid item md={6} lg={2} xs={6} sm={6} className='centeritall'>
<a href='' className='buttonm centeritall'>Chart</a>
</Grid>
<Grid item md={6} lg={2} xs={6} sm={6} className='centeritall'>
<a href='' className='buttonm centeritall'>Buy Now</a>
</Grid>
  </Grid>
  <br/>
      
      </div>
  )
}

export default Navbar